@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

}

.floatingRectangle {
  z-index: 2;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.backgroundBlur {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
}

.infoBox {
  padding: 1em;
  background-color: white;
  box-shadow: md;
  gap: 1em;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}